import React from 'react';

function ServicesHomeEight() {
    return (
        <>
            <section
                className="appie-services-2-area appie-services-8-area pt-90 pb-55"
                id="service"
            >
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-6 col-md-8">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Strong features for every need</h3>
                                <p>
                                    Create engaging customer experiences with WhatsApp and grow your
                                    business by going conversational with rich features.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div
                                        className="
                    appie-single-service-2 appie-single-service-about
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                                        data-wow-duration="2000ms"
                                        data-wow-delay="200ms"
                                    >
                                        <div className="icon">
                                            <img
                                                src="https://www.tyntec.com/sites/default/files/2022-08/icon_48x48_button_push_black_svg.svg"
                                                alt="icon_48x48_button_push_black_svg"
                                            />
                                        </div>
                                        <h4 className="title">Rich messaging</h4>
                                        <p>
                                            Go beyond text messages and send interactive and
                                            engaging messages using media elements and interactive
                                            buttons.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div
                                        className="
                    appie-single-service-2 appie-single-service-about
                    item-2
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                                        data-wow-duration="2000ms"
                                        data-wow-delay="400ms"
                                    >
                                        <div className="icon">
                                            <img
                                                src="https://www.tyntec.com/sites/default/files/2022-08/icon_48x48_message_templates_black_svg.svg"
                                                alt="icon_48x48_button_push_black_svg"
                                            />
                                        </div>
                                        <h4 className="title">Template Manager</h4>
                                        <p>
                                            Manage your Message Templates with oureasy-to-use
                                            Message Template Manager, and use localizations and
                                            variables.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div
                                        className="
                    appie-single-service-2 appie-single-service-about
                    item-3
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                                        data-wow-duration="2000ms"
                                        data-wow-delay="600ms"
                                    >
                                        <div className="icon">
                                            <img
                                                src="https://www.tyntec.com/sites/default/files/2022-08/icon_48x48_phone_byon_black_svg.svg"
                                                alt="icon_48x48_phone_byon_black_svg"
                                            />
                                        </div>
                                        <h4 className="title">Bring your own number</h4>
                                        <p>
                                            Use your existing phone number for WhatsApp Business.
                                            Any mobile, landline, or toll-free number works.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div
                                        className="
                    appie-single-service-2 appie-single-service-about
                    item-4
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                                        data-wow-duration="2000ms"
                                        data-wow-delay="200ms"
                                    >
                                        <div className="icon">
                                            <img
                                                src="https://www.tyntec.com/sites/default/files/2022-08/icon_48x48_verified_badge_black_svg_0.svg"
                                                alt="icon_48x48_verified_badge_black_svg"
                                            />
                                        </div>
                                        <h4 className="title">Verified business accounts</h4>
                                        <p>
                                            Become a ‘Verified Business’ to show your users that
                                            they can trust your business on WhatsApp.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="service-thumb">
                                <img
                                    src="https://www.tyntec.com/drimage/720/0/5478/image_whatsapp_business_use_cases_click_to_chat_2x.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesHomeEight;
